<template>
  <div>
    <button class="avatar-button" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
      <img class="avatar-button-icon" src="@imgs/main-avatar.png" />
      <pick-list class="avatar-button-list" :isShowList="isOpen" :itemArray="pickArray" :initialItem="initialInfo"
        :itemHeight="37" :paddingHeight="4" leaveAnimationTime="0s" @onSelected="onSelected"></pick-list>
    </button>
    <change-password-alert v-if="isShowChangePasswordAlert" v-model="isShowChangePasswordAlert"></change-password-alert>
  </div>
</template>

<script>
import PickList from "@c/common/pick-list/pick-list.vue";
import { CacheTool } from "@js/cache-tool.js";
export default {
  components: {
    PickList,
    ChangePasswordAlert: () =>
      import(
        "@c/common/alert/change-password-alert/change-password-alert.vue"
      ),
  },

  data() {
    return {
      pickArray: ["修改密码", "退出登录"],
      isOpen: false,
      initialInfo: undefined,
      isShowChangePasswordAlert: false,
    };
  },
  methods: {
    onMouseOver() {
      this.isOpen = true;
    },

    onMouseLeave() {
      this.isOpen = false;
    },

    onSelected(index) {
      this.isOpen = false;
      switch (this.pickArray[index]) {
        case "修改密码":
          this.onChangePassword();
          break;
        case "退出登录":
          this.logout();
          break;
        default:
          break;
      }
    },

    onMaskClick() {
      this.isOpen = false;
    },

    onChangePassword() {
      this.isShowChangePasswordAlert = true;
    },

    async logout() {
      try {
        await this.$api.logout()
        this.$store.dispatch('logout');
        CacheTool.logout();
        this.$router.push("Login");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    onModuleSelected(module) {
      const selectedModule = this.moduleArray.filter((item) => {
        return item.title == module;
      })[0].name;
      this.selectedModule = selectedModule;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-button {
  position: relative;
  @include z-index-3;

  &-icon {
    object-fit: contain;
    height: 32px;
  }

  &-list {
    top: 36px;
    left: -20px;
    min-width: 87px;

    /deep/.pick-list {
      &-item {
        color: black;
        font-size: 14px;
      }
    }
  }
}
</style>