import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@css/reset.css'
import "@js/constants.js";

//api
import "@js/api.js";

//toast组件
import mountToast from "@js/mount-toast.js";
Vue.use(mountToast);

import {Message} from 'element-ui';
import { Table, TableColumn } from "element-ui";

Vue.prototype.$message = Message
Vue.use(Table)
Vue.use(TableColumn)

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import VueVirtualScroller from 'vue-virtual-scroller'
Vue.use(VueVirtualScroller)

//echarts
let echarts = require('echarts/lib/echarts')
import { LineChart, BarChart, PieChart } from 'echarts/charts';
echarts.use([LineChart, BarChart, PieChart]);
import { GridComponent, LegendComponent, TooltipComponent, DataZoomComponent, MarkLineComponent, TitleComponent } from 'echarts/components';
echarts.use([GridComponent, LegendComponent, TooltipComponent, DataZoomComponent, MarkLineComponent, TitleComponent]);
Vue.prototype.$echarts = echarts

//剪切板
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
