<template>
    <button class="patient-tag" :class="tagClass" @click="onClick">
        {{ model.content }}
    </button>
</template>

<script>
export default {
    props: {
        model: Object
    },

    computed: {
        tagClass() {
            switch (this.model.type) {
                case PATIENT_TAG_TYPE_TYPE.添加:
                    return "add"
                case PATIENT_TAG_TYPE_TYPE.更多:
                    return "more"
                case PATIENT_TAG_TYPE_TYPE.基本信息:
                    return "info"
                case PATIENT_TAG_TYPE_TYPE.生活习惯:
                    return "habit"
                case PATIENT_TAG_TYPE_TYPE.病史:
                    switch (this.model.grade) {
                        case PATIENT_TAG_TYPE_GRADE.I级:
                            return "history1"
                        case PATIENT_TAG_TYPE_GRADE.II级:
                            return "history2"
                        case PATIENT_TAG_TYPE_GRADE.III级:
                            return "history3"
                        case PATIENT_TAG_TYPE_GRADE.IV级:
                            return "history4"
                        default:
                            return ""
                    }
                case PATIENT_TAG_TYPE_TYPE.其他:
                    return "other"
                default:
                    return ""
            }
        }
    },

    methods: {
        onClick() {
            this.$emit("onClick", this.model)
        }
    }
}
</script>

<style lang="scss" scoped>
.patient-tag {
    font-size: 13px;
    border-radius: 4.5px;
    min-height: 22px;
    padding: 0 9px;
    display: flex;
    text-align: left;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 18px;
}

.add {
    color: rgba($color: #ABABAB, $alpha: 0.88);
    border: 1px dashed #CDCDCD;
}

.more {
    font-size: 15px;
    color: #ABABAB;
    border: 1px dashed #E5E5E5;
}

.info {
    color: #52C41A;
    border: 1px solid #D9F7BE;
}

.habit {
    color: #F5904D;
    border: 1px solid #FFD2B4;
}

.other {
    color: #13C2C2;
    border: 1px solid #A7F7F7;
}

.history1 {
    color: #1677FF;
    border: 1px solid #BAE0FF;
}

.history2 {
    color: #1677FF;
    background-color: #E6F4FF;
    border: 1px solid #BAE0FF;
}

.history3 {
    color: #1677FF;
    background-color: #E6F4FF;
    border: 1px solid #1677FF;
}

.history4 {
    color: #FFFFFF;
    background-color: #1677FF;
    border: 1px solid #BAE0FF;
}
</style>