<template>
  <div class="main">
    <div class="main-container">
      <top-bar type="blue" :remindNumber="$store.state.remindNumber" @onClickRemind="onClickRemind">
        <template #top-bar-left>
          <organization-search></organization-search>
        </template>
        <template v-slot:module-picker>
          <module-picker ref="modulePicker" class="main-container-blue-bar-module-picker" @onSelected="onModuleSelected"></module-picker>
        </template>
      </top-bar>
      <div class="main-container-gray-container">
        <component class="main-container-gray-container-content" :is="selectedModuleName"></component>
      </div>
    </div>
    <weak-password-alert v-if="isShowWeakPasswordAlert" v-model="isShowWeakPasswordAlert" 
      @onConfirmClick="onWeakPasswordAlertConfirmClick"></weak-password-alert>
    <change-password-alert v-if="isShowChangePasswordAlert" v-model="isShowChangePasswordAlert" isForce></change-password-alert>
  </div>
</template>

<script>
import TopBar from "@c/common/top-bar/top-bar.vue";
import ModulePicker from "@c/main/main/module-picker/module-picker.vue";
import Patient from "@c/main/patient/patient.vue";
import { CacheTool } from "@js/cache-tool.js";
import OrganizationSearch from "@c/main/main/organization-search/organization-search.vue";
export default {
  components: {
    TopBar,
    ModulePicker,
    //组件
    Patient,
    OrganizationSearch,
    Sleep: () => import("@c/main/sleep/sleep.vue"),
    PAP: () => import("@c/main/pap/pap.vue"),
    Follow: () => import("@c/main/follow/follow.vue"),
    WorkCalendar: () => import('@c/main/work-calendar/work-calendar.vue'),
    RemoteTitration: () => import('@c/main/remote-titration/remote-titration.vue'),
    ObeyData: () => import('@c/main/obey-data/obey-data.vue'),
    RemindInfo: () => import('@c/main/remind-info/remind-info.vue'),
    SystemSetting: () => import('@c/main/system-setting/system-setting.vue'),
    WeakPasswordAlert: () =>
      import(
        "@c/common/alert/change-password-alert/weak-password-alert.vue"
      ),
    ChangePasswordAlert: () =>
      import(
        "@c/common/alert/change-password-alert/change-password-alert.vue"
      ),
  },

  data() {
    return {
      hospitalName: placeholderText(CacheTool.getAccountInfo().hospitalName),
      userName: placeholderText(CacheTool.getAccountInfo().name),
      bellIcon: require("@imgs/main-bell.png"),
      moduleArray: [
        {
          title: "患者列表",
          name: "Patient",
        },
        {
          title: "睡眠监测",
          name: "Sleep",
        },
        {
          title: "PAP治疗",
          name: "PAP",
        },
        {
          title: "患者随访",
          name: "Follow",
        },
        {
          title: "工作日历",
          name: "WorkCalendar",
        },
        {
          title: "提示信息",
          name: "RemindInfo",
        },
        {
          title: "系统设置",
          name: "SystemSetting",
        },
        {
          title: "依从性数据",
          name: "ObeyData",
        },
        {
          title: "远程滴定",
          name: "RemoteTitration",
        },
      ],
      selectedModuleName: CacheTool.getSelectedModule()?.name ?? "Patient",
      isShowWeakPasswordAlert: false,
      isShowChangePasswordAlert: false
    };
  },
  
  mounted() {
    const isStrongPassword = CacheTool.getIsStrongPassword()
    this.isShowWeakPasswordAlert = (isStrongPassword == 'false')
    if (this.$route.query.selectedIndex === '0') {
      this.onClickRemind()
    }
  },

  methods: {
    onModuleSelected(moduleTitle) {
      const selectedModule = this.moduleArray.filter((item) => {
        return item.title == moduleTitle;
      })[0];
      CacheTool.saveSelectedModule(selectedModule)
      this.selectedModuleName = selectedModule.name;
    },

    onWeakPasswordAlertConfirmClick() {
      this.isShowChangePasswordAlert = true
    },

    onClickRemind() {
      this.$refs.modulePicker.onSelectedTitle({index: 0})
    }

  },
};
</script>

<style lang="scss" scoped>
.main {
  &-container {
    min-width: 1800px; //blue-bar所有组件长度+间距之和
    min-height: 900px;
    display: flex;
    flex-direction: column;

    &-blue-bar {
      background-color: #2e6be6;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-title {
        color: white;
        font-size: 22px;
        font-weight: bold;
        margin-left: 35px;
      }

      &-right {
        margin-right: 39px;
        display: flex;
        align-items: center;

        &-remind {
          top: 2px; //强迫症
          margin-left: 20px;
        }

        &-avatar-button {
          margin-left: 45px;
          margin-right: 16px;
        }

        &-user-name {
          color: white;
          font-size: 14px;
        }

        &-module-picker {
          margin-right: 47px;
        }
      }
    }

    &-gray-container {
      background-color: #f2f2f7;
      flex: 1;
      box-sizing: border-box;
      padding: 21px 34px 38px;
      display: flex;
      justify-content: center;

      &-content {
        background-color: white;
        box-shadow: -14px 8px 24px 1px rgba(105, 105, 105, 0.12);
        width: 1828px;
      }
    }
  }
}
</style>