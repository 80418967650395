<template>
    <div class="tag-view">
        <div class="tag-view-content">
            <patient-tag v-for="(item, index) in tagArray" :key="index" :model="item" @onClick="onTagClick"></patient-tag>
        </div>
        <panel-alert-add-tag v-if="isShowAddTagAlert" :patientDetail="patientDetail" :model="currentTagModel"
            @onClose="onTagAlertClose"></panel-alert-add-tag>
    </div>
</template>

<script>
import PanelAlertAddTag from "@c/common/alert/panel-alert/panel-alert-add-tag/panel-alert-add-tag.vue";
import PatientTag from "@c/common/patient-tag/patient-tag";
import EventBus from "@js/event-bus.js";
export default {
    components: {
        PanelAlertAddTag,
        PatientTag
    },

    props: {
        patientDetail: Object,
        justAdd: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            tagArray: [],
            currentTagModel: undefined,
            isShowAddTagAlert: false
        }
    },

    created() {
        EventBus.$on("onPatientTagListChanged", (userId) => {
            if (this.patientDetail.id == userId) {
                this.tagListRequest()
            }
        })
    },

    beforeDestroy() {
        EventBus.$off("onPatientTagListChanged")
    },

    mounted() {
        if (this.justAdd) { return }
        this.tagListRequest()

    },

    methods: {
        onTagClick(tagModel) {
            switch (tagModel.type) {
                case PATIENT_TAG_TYPE_TYPE.添加:
                    this.currentTagModel = undefined;
                    this.isShowAddTagAlert = true
                    this.$emit("onAdd")
                    break
                default:
                    if (this.justAdd) {
                        return
                    } else {
                        this.currentTagModel = tagModel
                        this.isShowAddTagAlert = true
                        break;
                    }
            }
        },

        //tagAlert
        onTagAlertClose() {
            this.isShowAddTagAlert = false
        },

        async tagListRequest() {
            if (!this.patientDetail) { return }
            try {
                const params = {
                    hospitalId: this.patientDetail.hospitalId,
                    userId: this.patientDetail.id
                }
                const rawTagList = await this.$api.getPatientTagList(params)
                const rawTagArray = rawTagList.map((item) => {
                    return {
                        id: item.id,
                        type: item.categoryId,
                        grade: item.levelId,
                        content: item.labelName
                    }
                })
                const addTag = {
                    type: PATIENT_TAG_TYPE_TYPE.添加,
                    content: "+  标签"
                }
                this.tagArray = rawTagArray.concat([addTag])
            } catch (error) {
                this.$toast.showRed(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tag-view {
    height: 100px;
    overflow-x: scroll;
    box-sizing: border-box;
    padding: 20px 7px 9px 7px;

    &-content {
        display: flex;
        flex-wrap: wrap;

        >.patient-tag {
            margin-right: 5px;
            margin-bottom: 18px;
        }
    }
}
</style>