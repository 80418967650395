<template>
  <pop-view @onMaskClick="onMaskClick">
    <div class="panel-alert">
      <div class="panel-alert-top">
        <div class="panel-alert-top-left">
          <slot name="pre-title"></slot>
          <p class="panel-alert-top-left-title">{{ title }}</p>
          <slot name="sub-title"></slot>
          <p v-if="isShowInfo" class="panel-alert-top-left-info">
            带有<span class="panel-alert-top-left-info-star">*</span>
            的信息为必填信息
          </p>
        </div>
        <button class="panel-alert-top-close" :style="{
          backgroundImage: `url(${require('@imgs/panel-alert-close.png')})`,
        }" @click.stop="onCloseClick"></button>
      </div>
      <div class="panel-alert-content" :style="{ width: width }">
        <slot name="content"></slot>
      </div>
      <div v-if="isShowBottom" class="panel-alert-bottom">
        <button class="panel-alert-bottom-cancel" @click.stop="onCancelClick">
          取消
        </button>
        <slot name="middleButton"></slot>
        <button class="panel-alert-bottom-confirm" @click.stop="onConfirmClick">
          {{ confirmButton }}
        </button>
      </div>
    </div>
  </pop-view>
</template>

<script>
import PopView from "@c/common/pop-view/pop-view.vue";

export default {
  components: {
    PopView,
  },

  props: {
    title: String,
    confirmButton: {
      type: String,
      default: "确定",
    },

    isShowInfo: {
      type: Boolean,
      default: false,
    },

    isShowBottom: {
      type: Boolean,
      default: false,
    },

    width: String,
  },

  methods: {
    onMaskClick() {
      this.$emit("onMaskClick")
    },

    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onCancelClick() {
      this.$emit("onCloseClick");
    },

    onConfirmClick() {
      this.$emit("onConfirmClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-alert {
  position: relative;

  &-top {
    width: 100%;
    height: 59px;
    border-bottom: 1px solid rgba($color: black, $alpha: 0.09);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;

      &-title {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
        margin-left: 24px;
      }

      &-info {
        color: black;
        font-size: 12px;
        font-weight: 300;
        margin-top: 26px;
        margin-left: 10px;

        &-star {
          color: #ff0000;
        }
      }
    }

    &-close {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      margin-top: 16px;
      margin-right: 14px;
    }
  }

  &-content {
    box-sizing: border-box;
    padding: 20px 44px 34px 24px;
  }

  &-bottom {
    border-top: 1px solid rgba($color: black, $alpha: 0.09);
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    padding-right: 42px;
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;

    &-confirm {
      color: white;
      background-color: #2e6be6;
      font-size: 14px;
      border-radius: 3px;
      width: 65px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cancel {
      color: rgba($color: #000000, $alpha: 0.65);
      background-color: white;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #e8e8e8;
      width: 65px;
      height: 32px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>