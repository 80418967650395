//通过value获取key
function findKey(obj, value, compare = (a, b) => a === b) {
    return Object.keys(obj).find(k => compare(obj[k], value)); //找不到返回undefined
};

//获取key数组
function getObjectKeys(object) {
    var keys = [];
    for (var property in object) keys.push(property);
    return keys;
};

//获取value数组
function getObjectValues(object) {
    var values = [];
    for (var property in object) values.push(object[property]);
    return values;
};

function getKeyValueArray(object) {
    var dataArray = []
    for (var property in object) dataArray.push({
        title: property,
        value: object[property]
    });
    return dataArray;
}

export {
    findKey,
    getObjectKeys,
    getObjectValues,
    getKeyValueArray
}