<template>
  <div class="main-input">
    <img src="@imgs/main-search.png" />
    <input :placeholder="placeholder" v-model="value" @keyup.enter="onSearch" />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    inputValue: String
  },

  model: {
    prop: "inputValue",
    event: "onSearch"
  },

  data: function () {
    return {
      value: "",
    };
  },

  mounted(){
    this.value = this.inputValue
  },

  watch: {
    inputValue(value) {
      this.value = value
    }
  },

  methods: {
    onSearch: function () {
      this.$emit("onSearch", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-input {
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #2e6be6;
  padding-left: 13px;
  padding-right: 16px;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    margin-right: 6px;
  }

  input {
    color: black;
    font-size: 14px;
    flex: 1;
  }

  input::-webkit-input-placeholder {
    color: #b3b3b3;
    font-size: 14px;
  }
}
</style>