// 全局常量
window.PLACEHOLDER_TEXT = "-"

window.MSG_LIMIT_SECOND = 60;

// 枚举
window.USER_GENDER = {
    男: 1,
    女: 2,
}

window.USER_MARRIAGE = {
    未婚: 1,
    已婚: 2,
}

window.USER_CONTACTTYPE = {
    工作日: 1,
    非工作日: 2,
}

window.SLEEP_REPORT_AHI_LEVEL = {
    正常: 1,
    轻度: 2,
    中度: 3,
    重度: 4
}

window.SLEEP_REPORT_COMPLETENESS = {
    未知: 0,
    完整: 1,
    缺少血氧: 2,
    睡眠时间过短: 3,//（<3小时）
    监测时间过短: 4,//（<4小时）
    无效报告: 5
}

window.BREATH_MONITOR_TYPE_DAY = {
    正常: 1,
    当天没有使用: 2,
    未开始: 3
}

window.BREATH_MONITOR_TYPE_MONTH = {
    依从性大于等于70: 1,
    依从性小于70: 2,
    未开始或其他: 3
}

window.SLEEP_WORK_MODE = {
    成人模式: 0,
    儿童模式: 1
}

window.SLEEP_MONITOR_STATUS = {
    "未连接": 1,
    "已连接，未监测": 2,
    "已离床": 3,
    "监测中": 4,
    "活动中": 5
}

window.SLEEP_RING_CONNECT_STATUS = {
    未连接: 0,
    连接中: 1,
    已连接: 2,
    断开中: 3
}

window.SLEEP_RING_CHARGE_STATUS = {
    正常工作: 0,
    正在充电: 1,
    充满: 2,
    低电: 3
}

window.SLEEP_EPRIOD = {
    清醒期: 0,
    眼动期: 2,
    浅睡期: 3,
    深睡期: 4,
}

window.RECORD_TYPE = {
    压力滴定: 1,
    适宜性评估: 2,
    病史病历: 3
}

window.RECORD_STATUS = {
    草稿: 1,
    已发布: 2,
    已撤销: 3
}

window.CURE_MODE = {
    CPAP: 0,
    APAP: 1,
    BPAPS: 2,
    AutoBPAPS: 3,
    BPAPT: 4,
    BPAPST: 5,
}

window.PACKAGE_STATUS = [
    {
        name: '创建订单',
        value: '0',
    },
    {
        name: '基线数据待录入',
        value: '1',
    },
    {
        name: '待发货',
        value: '2',
    },
    {
        name: '待收货',
        value: '3',
    },
    {
        name: '已收货，待预约使用指导时间',
        value: '4',
    },
    {
        name: '服务待开始',
        value: '5',
    },
    {
        name: '压力滴定阶段',
        value: '6',
    },
    {
        name: '待生成滴定报告',
        value: '7',
    },
    {
        name: '适宜性评估阶段',
        value: '8',
    },
    {
        name: '待生成适宜性评估报告',
        value: '9',
    },
    {
        name: '已发布适宜性评估报告',
        value: '10',
    },
    {
        name: '服务完成待确认',
        value: '11',
    },
    {
        name: '服务完成，待寄回',
        value: '12',
    },
    {
        name: '服务结束',
        value: '13',
    },
    {
        name: '服务中止，已退款',
        value: '14',
    },
]

window.SLEEP_DATA_ARRAY = {
    入睡困难: 1,
    眠浅: 2,
    多梦: 3,
    易醒: 4,
    早醒: 5,
    睡眠需求减少: 6
}

window.EAT_DATA_ARRAY = {
    胃口差: 1,
    暴饮暴食: 2,
    体重下降: 3,
    体重增加: 4,
}

window.EMOTION_LOW_DATA_ARRAY = {
    不开心: 1,
    兴趣缺乏: 2,
    精力下降: 3,
}

window.EMOTION_ANXIOUS_DATA_ARRAY = {
    紧张: 1,
    担心: 2,
    多想: 3,
    忐忑不安: 4,
    坐卧不宁: 5,
    颤抖: 6,
    无法放松: 7
}

window.EMOTION_HIGH_DATA_ARRAY = {
    兴奋: 1,
    活动增多: 2,
    精力增加: 3,
}

window.DEPRESS_DATA_ARRAY = {
    注意力下降: 1,
    自我评价低: 2,
    无价值感: 3,
    认为前途暗淡悲观: 4,
}

window.ANXIOUS_DATA_ARRAY = {
    头重脚轻: 1,
    头痛: 2,
    头晕: 3,
    口干: 4,
    耳鸣: 5,
    出汗: 6,
    心慌: 7,
    胸闷: 8,
    上腹不适: 9,
}

window.CRAZY_DATA_ARRAY = {
    话多: 1,
    挥霍: 2,
    性欲增强: 3,
    计划多: 4,
    自我评价高: 5
}

window.SOCIAL_DATA_ARRAY = {
    影响工作: 1,
    影响生活: 2
}

window.RISK_DATA_ARRAY = {
    冲动: 1,
    消极: 2,
    外跑: 3
}

window.HEART_DATA_ARRAY = {
    高血压: 1,
    冠心病: 2,
    心律失常: 3,
    脑血管意外: 4
}

window.ENDOCRINE_DATA_ARRAY = { //内分泌
    甲状腺功能异常: 1,
    糖尿病: 2
}

window.BREATH_DATA_ARRAY = {
    鼻咽喉疾病: 1,
    支气管疾病: 2,
    肺病: 3
}

window.TUMOR_DATA_ARRAY = {
    呼吸系统癌症: 1,
    消化系统癌症: 2,
    泌尿系统癌症: 3,
    生殖系统癌症: 4,
    内分泌和神经内分泌相关癌症: 5,
    血液相关癌症: 6
}

window.SMOKE_HISTORY_ARRAY = {
    有吸烟史: 1,
    无吸烟史: 2
}

window.SMOKE_AGE_ARRAY = {
    "1年": 1,
    "2年": 2,
    "3年": 3,
    "4年": 4,
    "5年": 5,
    "6年": 6,
    "7年": 7,
    "8年": 8,
    "9年": 9,
    "10年": 10,
    "10年以上": 11,
}

window.SMOKE_NUMBER_ARRAY = {
    "1根": 1,
    "2根": 2,
    "3根": 3,
    "4根": 4,
    "5根": 5,
    "6根": 6,
    "7根": 7,
    "8根": 8,
    "9根": 9,
    "10根": 10,
    "10根以上": 11,
}

window.ALCOHOL_HISTORY_ARRAY = {
    有饮酒史: 1,
    无饮酒史: 2
}

window.ALCOHOL_AGE_ARRAY = {
    "1年": 1,
    "2年": 2,
    "3年": 3,
    "4年": 4,
    "5年": 5,
    "6年": 6,
    "7年": 7,
    "8年": 8,
    "9年": 9,
    "10年": 10,
    "10年以上": 11,
}

window.ALCOHOL_TYPE_ARRAY = {
    白酒: 1,
    啤酒: 2,
    红酒: 3,
    以上均有: 4
}

window.ALCOHOL_QUANTITY_ARRAY = {
    少量: 1,
    适量: 2,
    大量: 3
}

window.DRINK_ARRAY = {
    长期服用咖啡: 1,
    长期服用浓茶: 2
}

window.CASE_REPORT_TYPE_ARRAY = {
    上传睡眠报告: 1,
    上传其他报告: 2
}

window.PATIENT_TAG_TYPE_TYPE = {
    基本信息: 1,
    生活习惯: 2,
    病史: 3,
    其他: 4,
    添加: 5,
    更多: 6
}

window.PATIENT_TAG_TYPE_GRADE = {
    "I级": 1,
    "II级": 2,
    "III级": 3,
    "IV级": 4
}

window.EDUCATION_TYPE = {
    "大学本科及以上": 1,
    "大专": 2,
    "高中": 3,
    "高中以下": 4
}

//全局方法
//通常传入可选的字符串或数字。
//当值为空、0、""时显示占位符(空包括了undefined，null和NaN)
//有时传入的数字为0时也需要显示出来，可以将数字通过toFixed()方法转化为需要的字符串后传入。
//🍐placeholderText(this.remMinutes?.toFixed(0), "分钟")，
placeholderText = function (rawValue, unitStr = '') {
    if (!rawValue) {
        return PLACEHOLDER_TEXT
    } else {
        if (!unitStr) {
            return `${rawValue}`
        } else {
            return `${rawValue} ${unitStr}`
        }
    }

}

/**
 * AHI 级别判断， 0：正常，1：低，2：中，3：高, -1: 非法。
 * [level, min, max]
 */
ahiLevel = (val) => {
    if (val >= 0 && val < 5) { return [0, 0, 5] }
    else if (val >= 5 && val < 15) { return [1, 5, 15] }
    else if (val >= 15 && val < 30) { return [2, 15, 30] }
    else if (val >= 30 && val < 120) { return [3, 30, 120] }
    else return [-1, -1]
}
/**
 * 漏气 级别判断， 0：正常，1：低，2：中，3：高, -1: 非法。 
 */
leakLevel = (val) => {
    if (val >= 0 && val < 1) { return [0, 0, 1] }
    else if (val >= 1 && val < 5) { return [1, 1, 5] }
    else if (val >= 5 && val < 10) { return [2, 5, 10] }
    else if (val >= 10 && val < 100) { return [3, 10, 100] }
    else return [-1, -1]
}
/**
 * 依从性 级别判断， 0：正常，1：低，2：中，3：高, -1: 非法。
 */
complianceLevel = (val) => {
    if (val >= 70 && val <= 100) { return [0, 70, 100] }
    else if (val >= 60 && val < 70) { return [1, 60, 70] }
    else if (val >= 50 && val < 60) { return [2, 50, 60] }
    else if (val >= 0 && val < 50) { return [3, 0, 50] }
    else return [-1, -1]
}
/**
 * 时长 级别判断， 0：正常，1：低，2：中，3：高, -1: 非法。
 */
timeLevel = (val) => {
    if (val >= 6 * 3600 && val < 24 * 3600) { return [0, 6, 24] }
    else if (val >= 4 * 3600 && val < 6 * 3600) { return [1, 4, 6] }
    else if (val >= 2 * 3600 && val < 4 * 3600) { return [2, 2, 4] }
    else if (val >= 0 && val < 2 * 3600) { return [3, 0, 2] }
    else return [-1, -1]
}