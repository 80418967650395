<template>
  <div class="list-title-bar">
    <list-title-bar-item v-for="(item, index) of dataArray" :style="{ flex: item.flex }" :key="index"
      :model="item"></list-title-bar-item>
  </div>
</template>

<script>
import ListTitleBarItem from "@c/main/patient/list-title-bar/list-title-bar-item.vue";
export default {
  components: {
    ListTitleBarItem,
  },

  data() {
    return {
      dataArray: [
        { title: "", itemArray: ["姓名", "性别/年龄"], flex: 1 },
        { title: "NPPV治疗进度", itemArray: ["使用天数", "工作模式", "面罩", "最后使用日期"], flex: 2 },
        { title: "", itemArray: ["机构"], flex: 1.5 },
        { title: "", itemArray: ["查看报告"], flex: 1},
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
.list-title-bar {
  background-color: #f2f2f7;
  box-shadow: 0px 3px 6px 1px rgba($color: black, $alpha: 0.16); //阴影 颜色 要结合z-index放顶层
  height: 77px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}
</style>