<template>
  <div class="list-cell">
    <div class="list-cell-container" style="flex: 1;" @click="onClick('Holography')">
      <div class="list-cell-container-group">
        <div class="list-cell-content">
          <div class="list-cell-content-patient">
            <span class="list-cell-content-patient-name">{{ name }}</span>
            <tag-button :patientDetail="model"></tag-button>
          </div>
        </div>
        <div class="list-cell-content">{{ gender }}{{ age }}岁</div>
      </div>
    </div>
    <div class="list-cell-container" style="flex: 2" @click="onClick('Breath')">
      <div v-if="isShowNPPV" class="list-cell-container-group">
        <div class="list-cell-content">{{ nppvDay }}</div>
        <div class="list-cell-content">{{ nppvMode }}</div>
        <div class="list-cell-content list-cell-content-mask">
          <p v-if="maskArray[0]">{{ maskArray[0] }}</p>
          <p v-if="maskArray[1]">{{ maskArray[1] }}</p>
        </div>
        <div class="list-cell-content" :class="[lastUseTimeColor]">{{ lastUseTime }}</div>
      </div>
      <div v-else class="list-cell-content">
        无记录
      </div>
    </div>
    <div class="list-cell-container" style="flex: 1.5">
      <div class="list-cell-content">{{ hospitalName }}</div>
    </div>
    <div class="list-cell-container" style="flex: 1">
      <div class="list-cell-container-group">
        <button class="list-cell-content" :class="{ 'list-cell-blue': canCheckReport }" @click="onCheckDetail()">
          {{ canCheckReport ? "查看" : "无记录" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { findKey } from "@js/object-tool.js";
import { DateTool } from "@js/date-tool.js";
import { isEmpty } from '@js/empty-tool';
import TagButton from "@c/main/patient/list-cell/tag-button/tag-button.vue";

export default {
  props: {
    model: Object,
  },

  components: {
    TagButton
  },

  data() {
    return {
      rightIcon: require("@imgs/patient-right.png"),
      wrongIcon: require("@imgs/patient-wrong.png"),
      isShow: true
    };
  },

  computed: {
    name() {
      return placeholderText(this.model.name);
    },

    gender() {
      const sex = this.model.sex;
      if (sex) {
        return findKey(USER_GENDER, sex);
      } else {
        return PLACEHOLDER_TEXT;
      }
    },

    age() {
      return placeholderText(this.model.age);
    },

    canCheckReport() {
      return true
    },

    //NPPV
    isShowNPPV() {
      return this.model.mode || this.model.useDay != 0 || this.model.maskTypeName || this.model.lastUseTime;
    },

    nppvMode() {
      return placeholderText(this.model.mode);
    },

    lastUseTime() {
      return isEmpty(this.model.lastUseTime) ? '-' : DateTool.timeStrToStr(this.model.lastUseTime, "YYYY-MM-DD");
    },

    lastUseTimeColor() {
      if (isEmpty(this.model.lastUseTime)) return ''
      // 将秒时间戳转换为毫秒时间戳
      const currentTime = parseInt(Date.now() / 1000);
      const lastUseTime = parseInt(new Date(this.model.lastUseTime).getTime() / 1000)
      const unusedTime = currentTime - lastUseTime;
      if (unusedTime < 5 * 24 * 60 * 60) { // 不到5天，灰色
        return '';
      } else if (unusedTime < 10 * 24 * 60 * 60) { // 5至10天，蓝色
        return 'last-use-time-blue';
      } else if (unusedTime < 15 * 24 * 60 * 60) { // 10至15天，黄色
        return 'last-use-time-yellow';
      } else { // 15天及以上，红色
        return 'last-use-time-red';
      }
    },

    nppvDay() {
      return placeholderText(`${this.model.useDay}`);
    },

    maskArray() {
      const array = this.model.maskTypeName?.split(' ')
      if (array?.length && array[0] != '') {
        return this.model.maskTypeName.split(' ')
      } else {
        return ['-']
      }
    },

    hospitalName() {
      return placeholderText(this.model.hospitalName)
    }
  },

  methods: {
    onClick(moduleName) {
      this.$emit("onClick", {
        moduleName,
        model: this.model,
      });
    },

    onCheckDetail() {
      if (this.canCheckReport) {
        this.$emit("onCheckDetail", {
          model: this.model,
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.list-cell {
  background-color: #f2f2f7;
  height: 46px;
  margin-top: 11px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #d5e1fa !important;
  }

  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;

    &-group {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }

  &-content {
    color: #6b6b6b;
    font-size: 14px;
    line-height: 18px;
    min-width: 154px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-patient {
      display: flex;
      align-items: center;
      width: 100px;
      padding-left: 30px;

      &-name {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        max-width: 80px;
        box-sizing: border-box;
        white-space: nowrap;
        display: block;
        margin-right: 7px;
      }
    }

    &-mask {
      display: flex;
      flex-direction: column;
    }
  }

  &-blue {
    color: #2e6be6 !important;
  }

  &-red {
    color: #f45c50 !important;
  }

  &-green {
    color: #3abfa9 !important;
  }
}

.last-use-time-blue {
  color: #2480FF !important;
}

.last-use-time-yellow {
  color: #FAAD14 !important;
}

.last-use-time-red {
  color: #FF5B5D !important;
}
</style>