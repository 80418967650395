<template>
    <div>
        <panel-alert :title="model ? '编辑标签' : '新增标签'" width="305px" isShowBottom @onCloseClick="onClose"
            @onConfirmClick="onConfirm">
            <template v-slot:content>
                <div class="panel-alert-add-tag">
                    <div class="panel-alert-add-tag-item">
                        <must-label isMust text="分类"></must-label>
                        <list-picker v-model="type" :dataArray="typeArray"></list-picker>
                    </div>
                    <div v-if="isShowGrade" class="panel-alert-add-tag-item">
                        <must-label isMust text="等级"></must-label>
                        <list-picker v-model="grade" :dataArray="gradeArray"></list-picker>
                    </div>
                    <div class="panel-alert-add-tag-item">
                        <must-label isMust text="输入"></must-label>
                        <el-input class="address-item" v-model="content" />
                    </div>
                </div>
            </template>
            <template v-slot:middleButton>
                <button v-if="model" class="panel-alert-add-tag-delete-button" @click="onDelete">删除</button>
            </template>
        </panel-alert>
        <delete-tag-alert v-if="isShowDeleteTagAlert" @onCloseClick="onDeleteTagAlertClose"
            @onConfirmClick="onDeleteTagAlertConfirm"></delete-tag-alert>
    </div>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import MustLabel from "@c/common/alert/panel-alert/components/must-label.vue";
import ListPicker from "@c/common/alert/panel-alert/panel-alert-para-adjust/components/list-picker.vue";
import { getKeyValueArray } from "@js/object-tool.js";
import EventBus from "@js/event-bus.js";
import {
    Input,
} from "element-ui";
export default {
    components: {
        PanelAlert,
        MustLabel,
        ListPicker,
        elInput: Input,
        DeleteTagAlert: () => import('@c/common/alert/panel-alert/panel-alert-delete-tag.vue')
    },

    props: {
        patientDetail: Object,
        model: Object
    },

    data() {
        const patientTagTypeArray = getKeyValueArray(PATIENT_TAG_TYPE_TYPE)
        patientTagTypeArray.splice(4, 2)
        return {
            type: undefined,
            grade: 1,
            content: undefined,
            typeArray: patientTagTypeArray,
            gradeArray: getKeyValueArray(PATIENT_TAG_TYPE_GRADE),
            isShowDeleteTagAlert: false
        }
    },

    computed: {
        isShowGrade() {
            return this.type == PATIENT_TAG_TYPE_TYPE.病史
        }
    },

    mounted() {
        if (this.model) {
            this.type = this.model.type
            this.grade = this.model.grade
            this.content = this.model.content
        }
    },

    methods: {
        onClose() {
            this.$emit("onClose");
        },

        onConfirm() {
            if (!this.type || !this.content || (this.type == PATIENT_TAG_TYPE_TYPE.病史 && !this.grade)) {
                this.$message.error('内容不能为空')
                return
            }
            if (this.model) {
                this.editRequest()
            } else {
                this.addRequest()
            }
        },

        onDelete() {
            this.isShowDeleteTagAlert = true
        },

        onDeleteTagAlertClose() {
            this.isShowDeleteTagAlert = false
        },

        onDeleteTagAlertConfirm() {
            this.deleteRequest()
        },

        async addRequest() {
            try {
                const params = {
                    "hospitalId": this.patientDetail.hospitalId,
                    "categoryId": this.type,
                    "levelId": this.isShowGrade ? this.grade : undefined,
                    "userId": this.patientDetail.id,
                    "labelName": this.content
                }
                await this.$api.addPatientTag(params)
                this.$toast.showGreen("患者标签添加成功")
                this.$emit("onClose");
                EventBus.$emit("onPatientTagListChanged", this.patientDetail.id)
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        async editRequest() {
            try {
                const params = {
                    "id": this.model.id,
                    "hospitalId": this.patientDetail.hospitalId,
                    "categoryId": this.type,
                    "levelId": this.isShowGrade ? this.grade : undefined,
                    "userId": this.patientDetail.id,
                    "labelName": this.content
                }
                await this.$api.editPatientTag(params)
                this.$toast.showGreen("患者标签编辑成功")
                this.$emit("onClose");
                EventBus.$emit("onPatientTagListChanged", this.patientDetail.id)
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        async deleteRequest() {
            try {
                const params = {
                    "id": this.model.id,
                }
                await this.$api.deletePatientTag(params)
                this.$toast.showGreen("患者标签删除成功")
                this.$emit("onClose");
                EventBus.$emit("onPatientTagListChanged", this.patientDetail.id)
            } catch (error) {
                this.$toast.showRed(error)
            }
        }
    },
};
</script>
  
<style lang="scss" scoped>
/deep/.panel-alert-bottom {
    padding-right: 27px;
}

.panel-alert-add-tag {
    box-sizing: border-box;
    padding-top: 18px;

    /deep/.must-label {
        min-width: 0 !important;
        width: 52px;
        text-align: left;
    }

    &-item {
        margin-bottom: 23px;
        display: flex;
        align-items: center;

        >.list-picker {
            flex: 1;
        }

        >.el-input {
            flex: 1;

            /deep/.el-input__inner {
                font-size: 14px;
                color: black;
                height: 32px;
            }
        }

    }

    :last-child {
        margin-bottom: 0;
    }

    &-delete-button {
        color: #E05252;
        border: 1px solid #E05252;
        font-size: 14px;
        border-radius: 3px;
        width: 60px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
    }

}
</style>