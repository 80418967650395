import Vue from "vue";
import Vuex from "vuex";

import "@js/constants.js";
import { CacheTool } from "@js/cache-tool.js";



Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountInfo: CacheTool.getAccountInfo(),
    // //刷新时保存变量
    // selectedModuleIndex: 0,

    //recordFrameBar
    selectedRecordDurationModel: undefined, //包含了changedBySelector、selectedDatesArray两个参数
    selectedRecordDateModel: undefined, //包含了changedBySelector、selectedDate两个参数
    organizationModel: CacheTool.getOrganizationModule(),
    //弹窗位置相关
    zoom: 1,
    remindNumber: 0,
  },

  mutations: {
    //accountInfo
    saveAccountInfo(state, accountInfo) {
      CacheTool.saveAccountInfo(accountInfo);
      state.accountInfo = accountInfo;
    },

    removeAccountInfo(state) {
      CacheTool.removeAccountInfo();
      state.accountInfo = undefined;
    },

    //selectedRecordDurationModel
    saveSelectedRecordDurationModel(state, model) {
      state.selectedRecordDurationModel = model;
    },

    removeSelectedRecordDurationModel(state) {
      state.selectedRecordDurationModel = undefined;
    },

    //selectedRecordDateModel
    saveSelectedRecordDateModel(state, model) {
      state.selectedRecordDateModel = model;
    },

    removeSelectedRecordDateModel(state) {
      state.selectedRecordDateModel = undefined;
    },
    saveOrganizationModel(state, model) {
      CacheTool.saveOrganizationModule(model);
      state.organizationModel = model;
    },
    removeOrganizationModel(state) {
      CacheTool.removeOrganizationModule();
      state.organizationModel = undefined;
    },

    //accountInfo
    saveZoom(state, zoom) {
      state.zoom = zoom;
    },

    // remindNumber
    saveRemindNumber(state, remindNumber) {
      state.remindNumber = remindNumber;
    },
  },
  actions: {
    logout({ commit }) {
      commit('removeOrganizationModel')
      commit('removeAccountInfo')
    },

    async getRemindCount({ state, commit }) {
      if (!state.accountInfo) {
        commit('saveRemindNumber', 0)
        return
      }
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const startOfDayTimestamp = today.getTime()
        today.setHours(23, 59, 59, 999);
        const endOfDayTimestamp = today.getTime();
        const data = await Vue.prototype.$api.getDataExceptionsNum({ start: startOfDayTimestamp, end: endOfDayTimestamp, hospitalId: state.organizationModel?.id })
        commit('saveRemindNumber', data)
      } catch (error) {
        commit('saveRemindNumber', 0)
      }

    }

  },
  modules: {}
});
