<template>
  <div class="toast-container" v-if="isShowContainer">
    <transition name="toast">
      <div class="toast" :class="color" v-if="isShowToast">
        <img class="toast-left-icon" :src="leftIcon" />
        <p class="toast-content">{{ content }}</p>
      </div>
    </transition>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      //为了动画效果
      isShowContainer: false,
      isShowToast: false,
      color: "toast-red",
      content: "",
    };
  },

  computed: {
    leftIcon: function () {
      switch (this.color) {
        case "toast-red":
          return require("@imgs/toast-delete-red.png");
        case "toast-green":
          return require("@imgs/toast-check.png");
        default:
          break;
      }
    },
  },

  methods: {
    showGreen(content) {
      this._show({
        color: "toast-green",
        content,
      });
    },

    showRed(content) {
      this._show({
        color: "toast-red",
        content,
      });
    },

    reportShowRed(content) {
      this._show({
        color: "toast-red",
        content,
      });
    },

    _show({ color, content }) {
      this.color = color;
      this.content = content;
      //toast显示
      this.isShowContainer = true;
      this.isShowToast = true;
      //toast消失
      setTimeout(() => {
        this.isShowToast = false;
        setTimeout(() => {
          this.isShowContainer = false;
        }, 300);
      }, 2000);
    },

  },
};
</script>
 
<style lang="scss" scoped>

.toast-container {
  z-index: 2003;
  // @include z-index-max;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toast {
  z-index: 2003;
  // @include z-index-max;
  position: absolute;
  bottom: 40%;
  color: #505659;
  font-size: 14px;
  line-height: 14px;
  border-radius: 3px;
  min-width: 150px;
  height: 40px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 15px;
  display: flex;
  align-items: center;

  &-red {
    background-color: #ffeee6;
    border: 1px solid #ffddcc;
  }

  &-green {
    background-color: #e6faf0;
    border: 1px solid #ccf5e0;
  }

  &-left-icon {
    width: 14px;
    height: 14px;
  }

  &-content {
    margin-left: 10px;
  }
}

.toast-enter-active,
.toast-leave-active {
  transition: all 0.3s;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
