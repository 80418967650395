
<!-- 通过/deep/来设置item样式 -->
<template>
  <transition name="fold">
    <div
      v-if="isShowList"
      class="pick-list"
      ref="pickList"
      :style="{
        '--itemHeight': `${itemHeight}px`,
        '--listHeight': listHeight,
        '--paddingTop': `${paddingHeight}px`,
        '--enterAnimationTime': enterAnimationTime,
        '--leaveAnimationTime': leaveAnimationTime,
      }"
    >
      <button
        v-for="(item, index) of itemArray"
        :key="index"
        class="pick-list-item"
        :class="{ 'pick-list-item-selected': item == initialItem }"
        @click="onSelected(index)"
      >
        {{ item }}
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isShowList: {
      type: Boolean,
      default: false,
    },

    itemArray: {
      type: Array,
      default: [],
    },

    initialItem: String,

    //列表高度(为实现动画)
    itemHeight: {
      type: Number,
      default: 0,
    },

    paddingHeight: {
      type: Number,
      default: 0,
    },

    //动画时长
    enterAnimationTime: {
      type: String,
      default: "0.3s",
    },

    leaveAnimationTime: {
      type: String,
      default: "0.3s",
    },
  },

  computed: {
    listHeight() {
      const maxItemCount =
        this.itemArray.length > 6 ? 6 : this.itemArray.length;
      return `${maxItemCount * this.itemHeight + this.paddingHeight * 2}px`;
    },
  },

  watch: {
    isShowList(value) {
      if (value == true) {
        //滚动到选中位置
        const selectedIndex = this.itemArray.indexOf(this.initialItem);
        const scrollTop = this.itemHeight * selectedIndex + this.paddingHeight;
        setTimeout(() => {
          //否则获取不到pickList节点
          this.$refs.pickList.scrollTop = scrollTop;
        }, 300);
      }
    },
  },

  methods: {
    onSelected: function (index) {
      this.$emit("onSelected", index);
    },
  },
};
</script>

<style lang="scss" scoped>

.pick-list {
  @include z-index-3;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 8px 0px rgba($color: black, $alpha: 0.15);
  width: 100%;
  box-sizing: border-box;
  padding: var(--paddingTop) 0;
  height: var(--listHeight);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  &-item {
    line-height: var(--itemHeight);
    height: var(--itemHeight);
    width: 100%;
  }
}

//动画
.fold-enter-active {
  animation-duration: var(--enterAnimationTime);
  animation-name: fold-height-enter;
}
@keyframes fold-height-enter {
  0% {
    height: 0;
  }

  100% {
    height: var(--listHeight);
  }
}
.fold-leave-active {
  animation-duration: var(--leaveAnimationTime);
  animation-name: fold-height-leave;
}
@keyframes fold-height-leave {
  0% {
    height: var(--listHeight);
  }

  100% {
    height: 0;
  }
}
</style>