import Vue from "vue";
import httpNew from '@js/http-new.js';


class API {
    //登录
    sendVerifyCode = (params) => httpNew.post("/hx_app/base/sms/seed", params)
    loginByPassword = (params) => httpNew.post("/hx_app/web/admin/auth/login", params)
    loginByVerifyCode = (params) => httpNew.post("/hx_app/web/admin/auth/loginPhone", params)
    logout = (params) => httpNew.get("/hx_app/web/admin/logout", params)
    changePassword = (params) => httpNew.post("/hx_app/web/admin/changePassword", params)

    //管理员
    getAdminInfo = (params) => httpNew.get("/hx_app/web/admin/load", params)

    //患者
    getPatientList = (params) => httpNew.post("/hx_app/web/patient/user/pageList", params)
    addOrEditPatient = (params) => httpNew.post("/hx_app/web/patient/user/save", params)
    getPatientDetail = (params) => httpNew.get("/hx_app/web/patient/user/load", params)

    //呼吸机设备
    getDeviceList = (params) => httpNew.post("/hx_app/web/device/pageList", params)
    getDeviceDetail = (params) => httpNew.get("/hx_app/web/device/loadByUid", params)
    getModifyParaRecordList = (params) => httpNew.post("/hx_app/web/log/parameter", params)
    bindUserAndDevice = (params) => httpNew.post("/hx_app/web/device/bind", params)
    unbindUserAndDevice = (params) => httpNew.post("/hx_app/web/device/unBind", params)
    getMaskTypeList = () => httpNew.post("/hx_app/web/device/maskTypeList", {})
    getDeviceParams = (params) => httpNew.get("/hx_app/web/device/queryDeviceParameter", params)
    getBindDeviceParams = (params) => httpNew.get("/hx_app/web/device/queryUserParameter", params)
    setupDeviceParams = (params) => httpNew.post("/hx_app/web/device/saveParameter", params)


    //睡眠设备
    getSleepDeviceList = (params) => httpNew.post("/hx_app/web/sleepDevice/list", params)
    getSleepDeviceDetail = (params) => httpNew.get("/hx_app/web/sleepDevice/monitoringDeviceDetail", params)
    bindUserAndSleepDevice = (params) => httpNew.post("/hx_app/web/sleepDevice/lend", params)
    unbindUserAndSleepDevice = (params) => httpNew.post("/hx_app/web/sleepDevice/unbundled", params)

    //呼吸机报告
    getReportInfo = (params) => httpNew.post("/hx_app/web/report/reportInfo", params)
    getReportDuration = (params) => httpNew.post("/hx_app/web/report/scope", params)
    getReportStatisticsDetail = (params) => httpNew.post("/hx_app/web/report/multipleDetail", params)
    getSingleReportDetail = (params) => httpNew.get("/hx_app/web/report/detail", params)
    getSingleReportChartData = (params) => httpNew.post("/hx_app/web/report/detailSupply", params)
    modifyReportInfo = (params) => httpNew.post("/hx_app/web/report/update", params)
    getReportDateList = (params) => httpNew.post("/hx_app/web/report/reportDate", params)

    //睡眠报告
    getSleepReportList = (params) => httpNew.post("/hx_app/web/sleepReport/list", params)
    getSleepReportPagingList = (params) => httpNew.post("/hx_app/web/sleepReport/pageList", params)
    getSleepReportDuration = (params) => httpNew.post("/hx_app/web/sleepReport/scope", params)

    //血氧报告
    getBloodOxygenReportList = (params) => httpNew.get("/hx_app/xyRingReport/reportSelector", params)
    getBloodOxygenReportDateList = (params) => httpNew.get("/hx_app/xyRingReport/reportDate", params)
    getBloodOxygenReportDetail = (params) => httpNew.post("/hx_app/xyRingReport/load", params)
    saveBloodOxygenReportSuggestion = (params) => httpNew.post("/hx_app/xyRingReport/updateSuggest", params)
    saveBloodOxygenReportUserInfo = (params) => httpNew.post("/hx_app/xyRingReport/updateUserInfo", params)

    //呼吸机监测
    getPAPMonitorList = (params) => httpNew.post("/hx_app/web/report/summaryPap", params)
 
    //睡眠监测
    getSleepMonitorList = (params) => httpNew.post("/hx_app/web/sleepDevice/deviceUserList", params)

    //患者记录
    getAssessmentTemplate = (params) => httpNew.post("/hx_app/web/xyRingReport/patientTemplate", params)
    getPatientRecordList = (params) => httpNew.post("/hx_app/web/xyRingReport/pageList", params)
    getHistoryRecord = (params) => httpNew.get("/hx_app/web/xyRingReport/loadMedicalHistory", params)
    saveHistoryRecord = (params) => httpNew.post("/hx_app/web/xyRingReport/saveOrUpdateMedicalHistory", params)
    getEstimateRecord = (params) => httpNew.get("/hx_app/web/xyRingReport/loadAdaptabilityAssessment", params)
    saveEstimateRecord = (params) => httpNew.post("/hx_app/web/xyRingReport/saveOrUpdateAdaptabilityAssessment", params)
    getEstimateChartArray = (params) => httpNew.post("/hx_app/web/xyRingReport/adaptabilityAssessmentArr", params)
    getSingleReportParam = (params) => httpNew.get("/hx_app/web/xyRingReport/loadPressureTitrationParameterByReportId", params)
    getSingleReportTreatmentData = (params) => httpNew.post("/hx_app/web/xyRingReport/treatmentData", params)
    savePressureRecord = (params) => httpNew.post("/hx_app/web/xyRingReport/saveOrUpdatePressureTitration", params)
    getPressureRecord = (params) => httpNew.get("/hx_app/web/xyRingReport/loadPressureTitration", params)
    downloadRecordPDF = (params) => httpNew.post("/hx_app/web/xyRingReport/downloadReport", params)
    getRecordInfo = (params) => httpNew.get("/hx_app/web/xyRingReport/loadById", params)

    //全息视图
    uplodFileRequest = (file, onProgress = null) => { return httpNew.upload("/hx_app/base/aly/oss/upload", file, onProgress) }
    addOrEditCase = (params) => httpNew.post("/hx_app/huxiSpecializedDiseases/saveOrUpdate", params)
    getCaseList = (params) => httpNew.post("/hx_app/huxiSpecializedDiseases/pageList", params)
    getCaseDetail = (params) => httpNew.get("/hx_app/huxiSpecializedDiseases/load", params)
    getPreviousCaseDetail = (params) => httpNew.post("/hx_app/huxiSpecializedDiseases/previousLoad", params)
    getCaseReportList = (params) => httpNew.post("/hx_app/huxiSpecializedDiseases/viewReport", params)
    getQuestionnaireResultList = (params) => httpNew.get("/hx_app/h5/questionnaire/questionResultsList", params)

    //病情趋势
    getTrendDetail = (params) => httpNew.post("/hx_app/huxiSpecializedDiseases/diseaseTrendsStatistic", params)

    //日志
    getOperationLogList = (params) => httpNew.post("/hx_app/web/log/logList", params)
    getSystemLogList = (params) => httpNew.post("/hx_app/web/log/record", params)

    //添加设备
    addDevice = (params) => httpNew.post("/hx_app/web/device/save", params)
    //删除设备
    deleteDevice = (params) => httpNew.get("/hx_app/web/device/del", params)

    //工作日历
    //异常详情
    getWorkCalendarExceptionDetail = (params) => httpNew.post("/hx_app/web/work/calendar/exceptionDetail", params)
    //异常处理
    getWorkCalendarExceptionHandling = (params) => httpNew.post("/hx_app/web/work/calendar/exceptionHandling", params)
    //异常详情列表
    getWorkCalendarExceptionList = (params) => httpNew.post("/hx_app/web/work/calendar/exceptionList", params)
    //患者历史指标
    getWorkCalendarHistoricIndicators = (params) => httpNew.post("/hx_app/web/work/calendar/historicIndicators", params)
    /**
    * @name 工作日历列表
    * @property {number} start
    * @property {number} end
    */
    getWorkCalendarPageList = (params) => httpNew.post("/hx_app/web/work/calendar/pageList", params)
    //待办事项列表
    getWorkCalendarTodoList = (params) => httpNew.post("/hx_app/web/work/calendar/todoList", params)
    //获取医院列表
    getHospitalList = (params) => httpNew.post("/hx_app/web/hospital/list", params)
    //工作日历列表-使用数据异常数量
    getDataExceptionsNum = (params) => httpNew.post("/hx_app/web/work/calendar/dataExceptionsNum", params)
    //省市区
    getAreaList = (params) => httpNew.get("/hx_app/area/listByPid", params)

    //获取用户基线数据图表
    //assessmentType:
    // BASIC_INFORMATION(1, "基本信息"),
    // LIFESTYLE_HABITS(2, "生活习惯"),
    // MEDICAL_HISTORY(3, "病史"),
    // PSQI(4, "睡眠质量PSQI"),
    // ESS(6, "嗜睡问券(ESS)"),
    // GAD_7(7, "焦虑情绪GAD-7"),
    // PHQ_9(8, "焦虑情绪PHQ-9"),
    // PHQ_15(9, "躯体症状PHQ-15"),
    getQuestionResultsList = (params) => httpNew.get("/hx_app/h5/questionnaire/questionResultsList", params)

    //获取病史和生活习惯
    getQuestionnaireProblemList = (params) => httpNew.post("/hx_app/web/questionnaire/problemList", params)

    //修改问卷
    updateQuestionnaire = (params) => httpNew.post("/hx_app/web/questionnaire/updateQuestionnaire", params)

    //基线评估-检验报告
    getInspectionReport = (params) => httpNew.get("/hx_app/h5/questionnaire/reportParameters/inspectionReport", params)
    //基线评估-检验报告 添加或修改
    updateInspectionReport = (params) => httpNew.post("/hx_app/h5/questionnaire/reportParameters/saveOrUpdate", params)
    //基线评估-检验报告 睡眠报告手机号查询
    getInspectionSleepReport = (params) => httpNew.get("/hx_app/h5/questionnaire/reportParameters/sleepReport", params, false)
    //睡眠用户手机号或姓名查询
    getInspectionSleepUser = (params) => httpNew.post("/hx_app/h5/questionnaire/reportParameters/sleepUser", params)
    //生成pdf
    generatePdf = (params) => httpNew.post("/hx_app/base/pdf/generate", params)

    //获取基线数据
    getBaseDataReport = (params) => httpNew.get("/hx_app/h5/questionnaire/reportParameters/inspectionReport", params)

    //检验报告文件分类-查询列表
    getInspectionReportCategory = (params) => httpNew.post("/hx_app/h5/questionnaire/reportParameters/inspectionReportCategory", params)

    //患者标签
    getPatientTagTypeList = (params) => httpNew.post("/hx_app/huxiLabelCategory/list", params)
    getPatientTagIllnessLevelList = (params) => httpNew.post("/hx_app/huxiLabelLevel/list", params)
    addPatientTag = (params) => httpNew.post("/hx_app/huxiUserLabel/save", params)
    editPatientTag = (params) => httpNew.post("/hx_app/huxiUserLabel/update", params)
    deletePatientTag = (params) => httpNew.get("/hx_app/huxiUserLabel/del", params)
    getPatientTagList = (params) => httpNew.post("/hx_app/huxiUserLabel/list", params, false)
    //使用数据异常需关注中的数量
    getWorkCalendarExceptionCount = (params) => httpNew.post("/hx_app/h5/questionnaire/reportParameters/inspectionReportCategory", params)

    //整合的全部报告
    getAllReportList = (params) => httpNew.post("/hx_app/web/report/allReportList", params)
    //远程滴定管理-获取用户列表
    getTitrationPatientList = (params) => httpNew.post("/hx_app/web/titration/userList", params)

    //远程滴定管理-获取呼吸报告图表数据
    getTitrationChartData = (params) => httpNew.post("/hx_app/web/titration/loadSupply", params, false)
    //远程滴定管理-获取图表数据开始结束时间
    getTitrationPeriod = (params) => httpNew.post("/hx_app/web/titration/period", params)
}

Vue.prototype.$api = new API();


