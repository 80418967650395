<template>
    <el-popover placement="right" :visible-arrow="false" trigger="hover" @show="show" :append-to-body="false" >
        <!-- 触发popover的元素 -->
        <button slot="reference" class="check-button">{{ patientDetail.userTagCount }}</button>
        <!-- popover显示的元素 -->
        <tag-view class="tag-view" ref="tagView" :patientDetail="patientDetail" justAdd></tag-view>
    </el-popover>
</template>

<script>
import TagView from "@c/common/alert/panel-alert/panel-alert-add-patient/tag-view.vue";
import {
    Popover,
} from "element-ui";
export default {
    components: {
        TagView,
        //element
        elPopover: Popover,
    },

    props: {
        patientDetail: Object
    },

    data() {
        return {
            index: 0,
            checkIcon: require("@imgs/patient-list-patient-tag-check.png"),
        }
    },

    computed: {
        zoom() {
            return this.$store.state.zoom
        },
    },

    methods: {
        show() {
            this.$refs.tagView.tagListRequest()
        }
    }
}
</script>

<style lang="scss" scoped>
.check-button {
    background-color: #EFEFEF;
    color: #ABABAB;
    border: 1px solid #C9C9C9;
    border-radius: 22px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tag-view {
    /deep/.tag-view-content {
        width: 400px;
    }
}

</style>