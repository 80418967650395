import Router from "../../router/index";
import axios from "axios";
import { CacheTool } from "@js/cache-tool.js";
import { Loading } from "element-ui";
import store from '../../store'

var loading = null;

const httpNew = axios.create({
    baseURL: process.env.VUE_APP_API_URI_NEW
})

// 拦截器
// request
httpNew.interceptors.request.use(config => {
    //loading
    loading?.close()
    if (config.custom && config.custom?.showLoading == false) {

    } else {
        loading = Loading.service({
            fullscreen: true,
            background: "rgba(0,0,0,0.1)"
        });
    }
    //loginInfo
    const loginInfo = CacheTool.getLoginInfo();
    if (loginInfo) {
        if (config.headers) {
            config.headers.token = loginInfo.token;
            config.headers.uid = loginInfo.uid;
        } else {
            config.headers = {
                token: loginInfo.token,
                uid: loginInfo.uid,
            };
        }
    }
    return config;
});

// response
httpNew.interceptors.response.use(
    response => {
        loading?.close();
        const code = response.data.code;
        switch (code) {
            case "1":
                return Promise.resolve(response.data.data);
            case "8": //没有token
            case "9": //token查不到
            case "11": //token过期
                store.dispatch('logout');
                CacheTool.logout();
                Router.push({ path: "/login" });
            default:
                return Promise.reject(response.data.msg);
        }
    },
    error => {
        loading.close();
        if (error.response) {
            return Promise.reject(error.response.data.msg);
        } else {
            return Promise.reject("服务器出错");
        }
    }
);

// 封装方法
async function post(uri, params, loading = true) {
    return httpNew.post(uri, params, {
        custom: {
            showLoading: loading
        }
    })
}

async function get(uri, params, loading = true) {
    return httpNew.get(uri, {
        params,
        custom: {
            showLoading: loading
        }
    })
}

async function upload(url, file, onProgress) {
    let formData = new FormData()
    formData.append('file', file)
    return httpNew.post(
        url,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: onProgress, timeout: 1800000 }
    );
}

export default { post, get, upload }