<template>
  <div class="page-list-container">
    <div class="page-list-container-content" :style="{ height: height }">
      <slot></slot>
      <p v-if="total === 0" class="page-list-container-content-empty-info">
        {{ emptyInfo }}
      </p>
      <div v-if="total != 0" class="page-list-container-content-space"></div>
      <pagination
        v-if="total != 0"
        class="page-list-container-content-pagination"
        :total="total"
        :pageSize="pageSize"
        :currentPage="currentPage"
        @pagechange="onPageChange"
      ></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@c/common/paging-list/pagination.vue";
export default {
  components: {
    Pagination,
  },

  props: {
    total: Number, //显隐空页面提示
    totalPage: Number, //分页器总页数
    inputCurrentPage: Number, //分页器当前页
    pageSize: { 
      type: Number,
      default: 10,
    },
    height: {
      type: String,
      default:"430px"
    },

    emptyInfo: {
      type: String,
      default: "暂无相应数据",
    },
  },

  data() {
    return {
      currentPage: 0,
    };
  },

  created() {
    this.currentPage = this.inputCurrentPage;
  },

  watch: {
    inputCurrentPage() {
      this.currentPage = this.inputCurrentPage;
    },
  },

  methods: {
    onPageChange: function (currentPage) {
      this.$emit("pagination", currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>

.page-list-container {
  background-color: white;

  &-content {
    position: relative;
    font-size: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-empty-info {
      color: rgba($color: black, $alpha: 0.85);
      font-size: 14px;
      width: 100%;
      margin-top: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-space {
      flex: 1;
    }

    &-pagination {
      align-self: flex-end;
      margin-right: 30px;
    }
  }
}
</style>