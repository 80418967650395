import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from "../views/Main.vue";
import { CacheTool } from "@js/cache-tool.js";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: "/user-main",
    name: "UserMain",
    component: () => import("../views/UserMain.vue")
  },
  {
    path: "/report-frame",
    name: "ReportFrame",
    component: () => import("../views/ReportFrame.vue")
  },
  {
    path: "/record-frame",
    name: "RecordFrame",
    component: () => import("../views/RecordFrame.vue")
  },
  {
    path: "/pdf-report",
    name: "PDFReport",
    component: () => import("../views/PDFReport.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_MODE,
  routes
})

router.beforeEach((to, from, next) => {
  const loginInfo = CacheTool.getLoginInfo();
  if (to.name === "Report") {
    // 如果要跳转详情，直接进入
    next()
  } else if (loginInfo || to.name === "Login") {
    // 如果存在登录信息,或者不存在登录信息，但进入的页面是登录页面,则直接进入
    next();
  } else {
    // 如果不存在登录信息,则跳转到登录页面.要带上当前的页面地址，方便登录完成后做回跳
    next({
      name: "Login"
      
    });
  }

});

export default router
