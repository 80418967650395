import dayjs from "dayjs";

import duration from "dayjs/plugin/duration"

dayjs.extend(duration)

class DateTool {

    static day(rawData) { //可为Date、timestamp、timeString
        if (!rawData) { return null }
        return dayjs(rawData) //返回dayjs对象
    }

    static dateToStr(date, format) {
        if (!date) { return null }
        return dayjs(date).format(format ?? 'YYYY-MM-DD HH:mm:ss')
    }

    static timestampToStr(timestamp, format) {
        if (!timestamp) { return null }
        return dayjs.unix(timestamp * 1).format(format ?? 'YYYY-MM-DD HH:mm:ss')
    }

    static milliTimestampToStr(milliTimestamp, format) {
        if (!milliTimestamp) { return null }
        return dayjs(milliTimestamp * 1).format(format ?? 'YYYY-MM-DD HH:mm:ss')
    }

    static timeStrToStr(dateStr, format) {
        if (!dateStr) { return null }
        return dayjs(dateStr).format(format ?? 'YYYY-MM-DD HH:mm:ss')
    }

    static timeStrToTimestamp(dateStr) {
        if (!dateStr) { return null }
        return dayjs(dateStr).unix()
    }

    static timeStrToMilliTimestamp(dateStr) {
        if (!dateStr) { return null }
        return dayjs(dateStr).valueOf()
    }

    //秒数转化为时分
    static formatSeconds(rawValue) {
        const value = parseInt(rawValue)
        let result
        if (isNaN(value)) {
            result = null
        } else if (value == 0) {
            result = '0分钟'
        } else {
            //time
            const dur = dayjs.duration(value, 'seconds');
            const days = dur.days();
            const hours = dur.hours();
            const minutes = dur.minutes();
            const seconds = dur.seconds()
            //timeStr
            const daysStr = days ? `${days}天` : ""
            const hoursStr = hours ? `${hours}小时` : ""
            const minutesStr = minutes ? `${minutes}分钟` : ""
            const secondsStr = value < 60 && seconds ? `${seconds}秒` : ""
            //result
            result = `${daysStr}${hoursStr}${minutesStr}${secondsStr} `
        }
        return result
    }

    static secondsToHoursMinutes(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        let result = '';

        if (hours > 0) {
            result += `${hours} 小时`;
        }

        if (minutes > 0 || result === '') {
            result += `${minutes} 分钟`;
        }

        return result;
    }

    static minutesToHours(min) {
        const hours = Math.floor(min / 60);
        const minutes = Math.floor(min % 60);
        let result = '';

        if (hours > 0) {
            result += `${hours} 小时`;
        }

        if (minutes > 0 || result === '') {
            result += `${minutes} 分钟`;
        }

        return result;
    }
}

export { DateTool };