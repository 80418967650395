<template>
  <div>
    <list-shell ref="listShell" @onAddPatientSucceed="onAddPatientSucceed">
      <template v-slot:add-right>
        <main-input class="patient-search" placeholder="支持姓名、手机号码、序列号查询" v-model:inputValue="keyword" @onSearch="onSearch"></main-input>
      </template>
      <template v-slot:content>
        <page-list-container height="700px" :total="total" :totalPage="totalPage" :inputCurrentPage="currentPage"
          @pagination="onPagination">
          <div>
            <list-title-bar></list-title-bar>
            <list-cell v-for="(item, index) of dataArray" :key="index" :model="item" @onClick="onCellClick"
              @onCheckDetail="onCellCheckDetail"></list-cell>
          </div>
        </page-list-container>
      </template>
    </list-shell>
    <all-report-list-alert v-if="isShowAllReportListAlert" :uid="alertUid" :hospitalId="alertHostpitalId"
      @onCloseClick="onSleepListAlertClose"></all-report-list-alert>
  </div>
</template>

<script>
import ListShell from "@c/main/patient/list-shell/list-shell.vue";
import MainInput from "@c/main/patient/input.vue";
import ListTitleBar from "@c/main/patient/list-title-bar/list-title-bar.vue";
import ListCell from "@c/main/patient/list-cell/list-cell.vue";
import PageListContainer from "@c/common/paging-list/paging-list.vue";
import EventBus from "@js/event-bus.js";

export default {
  components: {
    ListShell,
    MainInput,
    ListTitleBar,
    ListCell,
    PageListContainer,
    AllReportListAlert: () =>
      import("@c/common/alert/panel-alert/panel-alert-all-report-list.vue"),
  },

  data() {
    return {
      //搜索
      keyword: "",
      //列表
      dataArray: [],
      total: 0,
      totalPage: 0,
      currentPage: 1,
      //弹窗
      isShowAllReportListAlert: false,
    };
  },
  computed: {
    organizationModel() {
      return this.$store.state.organizationModel;
    }
  },
  watch: {
    organizationModel(newVal, oldVal) {
      if (newVal?.id != oldVal?.id) {
        this.keyword = ''
        this.currentPage = 1
        this.getPatientList();
      }
    }
  },

  created() {
    this.getPatientList();
    EventBus.$on("onPatientTagListChanged", () => {
      this.getPatientList()
    })
  },

  beforeDestroy() {
    EventBus.$off("onPatientTagListChanged")
  },

  methods: {
    onAddPatientSucceed() {
      //刷新页面
      this.keyword = "";
      this.currentPage = 1;
      this.getPatientList();
    },

    onSearch() {
      this.currentPage = 1;
      this.getPatientList();
    },

    onCellClick(data) {
      this.$refs.listShell.openUserMain(data.model.id, data.moduleName);
    },

    onCellCheckDetail(data) {
      this.alertUid = data.model.id;
      this.alertHostpitalId = data.model.hospitalId
      this.isShowAllReportListAlert = true;
    },

    onPagination: function (currentPage) {
      this.currentPage = currentPage;
      this.getPatientList();
    },

    onSleepListAlertClose() {
      this.isShowAllReportListAlert = false;
    },

    // method
    async getPatientList() {
      try {
        const params = {
          keyWord: this.keyword,
          pageNum: this.currentPage,
          hospitalId: this.organizationModel?.id ?? 0,
          pageSize: 10,
        };
        const data = await this.$api.getPatientList(params);
        this.dataArray = data.list;
        this.total = data.total;
        this.totalPage = data.pages;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient {
  &-search {
    width: 341px;
    height: 36px;
    margin-left: 29px;
  }
}
</style>