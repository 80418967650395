<template>
    <div class="header-search">
        <el-autocomplete class="header-search-input" v-model="queryValue" placeholder="全部" :fetch-suggestions="querySearch"
            popper-class="header-search-pop" @select="handleSelect" suffix-icon="el-icon-search">
        </el-autocomplete>
        <i class="header-search-warning-icon el-icon-warning-outline"></i>
        <span class="header-search-warning-text">请搜索机构并选择</span>
    </div>
</template>
  
<script>
import { Autocomplete } from "element-ui";
import { CacheTool } from "@js/cache-tool.js";
export default {
    components: {
        ElAutocomplete: Autocomplete,
    },
    data() {
        return {
            queryValue: '',
            list: [],
        };
    },
    computed: {
        options() {
            return this.list.map(el => ({
                id: el.id,
                value: el.name
            }))
        }
    },
    watch: {
        queryValue(newVal, oldVal) {
            if (newVal == '') {
                this.$store.commit('removeOrganizationModel')
            }
        },
        '$store.state.organizationModel': {
            handler(newVal) {
                this.queryValue = newVal?.name ?? '';
            }
        }
    },
    mounted() {
        if (this.$store.state.organizationModel) {
            this.list.splice(1, 1, this.$store.state.organizationModel);
            this.queryValue = this.$store.state.organizationModel.name;
        }
    },
    methods: {
        //Action:
        //Logic:
        async querySearch(queryString, cb) {
            try {
                const data = await this.$api.getHospitalList({ name: queryString.trim(), pageNum: 1, pageSize: 2000 })
                this.list = data
                cb(this.options)
            } catch (e) {
                cb([]);
            }
        },
        handleSelect(item) {
            this.queryValue = item.value
            const newItem = this.list.find((el) => {
                return el.id === item.id
            })
            newItem ? this.$store.commit('saveOrganizationModel', newItem) : {}
        },
    },
}
</script>
  
<style lang="scss" scoped>
@mixin iconAtt {
    height: 100%;
    width: 25px;
    color: #FFFFFF;
}

.header-search {
    display: flex;
    align-items: center;
    height: 100%;

    &-pop {
        li {
            line-height: normal;
            padding: 7px;

            .name {
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .addr {
                font-size: 12px;
                color: #b4b4b4;
            }

            .hidhlighted .addr {
                color: #ddd;
            }
        }
    }

    &-input {
        margin-left: 26px;

        /deep/.el-input__inner {
            background-color: #2E6BE6;
            color: #ffffff;
            border-color: white;
        }
    }

    &-warning-icon {
        margin-left: 9px;
        @include iconAtt;
    }

    &-warning-text {
        margin: {
            left: 2px;
            right: 20px;
        }

        display: inline-block;
        color: #FFFFFF;
        font-size: 12px;
        white-space:nowrap;
    }
}
</style>
  
<style lang="scss">
.header-search-pop {
    zoom: 100%;

    @media (max-width: 1680px) {
        zoom: 90%;
    }

    @media (max-width: 1440px) {
        zoom: 80%;
    }
    @media (max-width: 1440px) {
        zoom: 75%;
    }
}
</style>