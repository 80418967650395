<template>
  <button class="add-user" @click="onClick">
    <img class="add-user-icon" :src="icon" />
    <p class="add-user-title">{{ title }}</p>
  </button>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
  },

  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>

.add-user {
  background-color: white;
  border: 1px solid #2e6be6;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &-icon {
    width: 21px;
    height: 22.5px;
    margin-left: 10px;
  }

  &-title {
    color: #2e6be6;
    font-size: 14px;
    margin-left: 8px;
  }
}
</style>