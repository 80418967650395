<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  watch:{
    '$store.state.organizationModel' : {
      handler(newVal) {
        console.log('organizationModel change')
        this.$store.dispatch('getRemindCount')
      }
    }
  },
  data() {
    return {
      timeId: void 0
    }
  },
  mounted() {
    this.onZoomChanged()

    window.addEventListener("resize", () => {
      this.onZoomChanged()
    })

    this.$watch(() => this.$store.state.accountInfo, (newVal) => {
      if (newVal) {
        this.addRemindCountListener()
      } else {
        this.removeRemindCountListener()
      }
      
    }, {immediate: true})  
  },

  destroyed() {
    window.removeEventListener("resize", () => {
      this.onZoomChanged()
    })
  },

  methods: {
    onZoomChanged() {
      const app = document.getElementById("app");
      const zoom = window.getComputedStyle(app, null).zoom;
      this.$store.commit("saveZoom", zoom)
    },
    addRemindCountListener() {
      this.$store.dispatch('getRemindCount')
      this.timeId = setInterval(() => {
        this.$store.dispatch('getRemindCount')
      }, 60*1000)
    },
    removeRemindCountListener() {
      if (!this.timeId) {
        return
      }
      clearInterval(this.timeId)
      this.timeId = void 0
    }
  }
}

</script>

<style>
#app {
  width: 100%;
}

@media (max-width: 1680px) {
  #app {
    zoom: 90%;
  }
}

@media (max-width: 1440px) {
  #app {
    zoom: 80%;
  }
}

@media (max-width: 1366px) {
  #app {
    zoom: 75%;
  }
}
</style>
