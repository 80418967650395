<template>
  <button class="drop-button" :style="{ backgroundColor: backgroundColor }" @mouseover="onMouseOver"
    @mouseleave="onMouseLeave" @click="onSelectedTitle">
    <div class="drop-button-container">
      <p class="drop-button-container-title">{{ smartTitle }}</p>
      <img v-if="showTitleIcon" class="drop-button-container-icon" :src="arrowIcon" />
    </div>
    <pick-list class="drop-button-list" :isShowList="isOpen" :itemArray="model.itemArray"
      :initialItem="currentModuleTitle" :itemHeight="22" :paddingHeight="7" leaveAnimationTime="0s"
      @onSelected="onSelected"></pick-list>
  </button>
</template>

<script>
import PickList from "@c/common/pick-list/pick-list.vue";
export default {
  components: {
    PickList,
  },

  props: {
    index: Number,
    model: Object,
    currentModuleTitle: String,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    arrowIcon() {
      return this.isOpen
        ? require("@imgs/main-arrow-down.png")
        : require("@imgs/main-arrow-up.png");
    },

    containsCurrentModuleTitle() {
      if (!this.hasItems) {
        return this.currentModuleTitle == this.model.title
      } else {
        return this.model.itemArray.indexOf(this.currentModuleTitle) != -1;
      }
    },

    backgroundColor() {
      return this.containsCurrentModuleTitle ? "#145BCC" : "#2E6BE6";
    },

    smartTitle() {
      if (this.containsCurrentModuleTitle) {
        return this.currentModuleTitle;
      } else {
        return this.model.title;
      }
    },

    showTitleIcon() {
      return !this.hasItems ? false : true
    },

    hasItems() {
      return this.model.itemArray.length != 0
    }
  },

  methods: {
    onMouseOver() {
      if (!this.hasItems) {
        return;
      }
      this.isOpen = true;
    },

    onMouseLeave() {
      this.isOpen = false;
    },

    onSelectedTitle() {
      if (this.hasItems) {
        return;
      }
      this.isOpen = false;
      this.$emit("onSelectedTitle", {
        index: this.index,
      });
    },

    onSelected(index) {
      this.isOpen = false;
      this.$emit("onSelected", {
        index: this.index,
        selectedIndex: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      color: white;
      font-size: 14px;
    }

    &-icon {
      width: 11px;
      height: 7px;
      margin-left: 6px;
      transition: all 0.3 ease-out;
    }
  }

  &-list {
    top: 43px;
    min-width: 87px;

    /deep/.pick-list {
      &-item {
        color: #6b6b6b;
        font-size: 14px;

        &-selected {
          color: #145bcc !important;
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>