<template>
  <button class="remind" @click.stop.prevent="$emit('onClickRemind')">
    <img class="remind-bell" :src="icon" />
    <div v-show="remindNumber !== '0' && remindNumber !== ''" class="remind-capsule">
      <p class="remind-capsule-number"> {{ remindNumber }} </p>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    remindNumber: String,
    icon: String,
  },
};
</script>

<style lang="scss" scoped>
.remind {
  position: relative;

  &-bell {
    width: 28px;
    height: 27px;
  }

  &-capsule {
    position: absolute;
    top: -1px;
    left: 15px;
    background-color: #FF4D4F;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFFF;

    &-number {
      color: white;
      font-size: 12px;
      transform: scale(0.83);
      text-align: center;
      line-height: 18px;
    }
  }
}
</style>