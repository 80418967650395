import Toast from '@c/common/toast.vue';

const mountToast = {};
mountToast.install = function (Vue) {
    //根据toast定义生成虚拟节点
    const toastContrustor = Vue.extend(Toast);
    const toast = new toastContrustor();
    //创建一个div来渲染这个虚拟节点
    toast.$mount(document.createElement('div'))
    //将div添加到body上
    document.body.appendChild(toast.$el)
    //全局vue文件中调用
    Vue.prototype.$toast = toast;
}

export default mountToast;