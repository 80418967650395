import { isEmpty } from '@js/empty-tool';

class CacheTool {
  static LOGIN_INFO_KEY = "loginInfo"
  static ACCOUNT_INFO_KEY = "accountInfo"
  static IS_STRONG_PASSWORD_KEY = "isStrongPassword"
  static MAIN_SELECTED_MODULE_KEY = "mainSelectedModule"
  static ORGANIZATION_KEY = "organization"

  //login
  static saveLoginInfo(loginInfo) {
    localStorage.setItem(CacheTool.LOGIN_INFO_KEY, JSON.stringify(loginInfo));
  }

  static removeLoginInfo() {
    localStorage.removeItem(CacheTool.LOGIN_INFO_KEY);
  }

  static getLoginInfo() {
    return JSON.parse(localStorage.getItem(CacheTool.LOGIN_INFO_KEY));
  }

  //isStrongPassword
  static saveIsStrongPassword(isStrongPassword) {
    localStorage.setItem(CacheTool.IS_STRONG_PASSWORD_KEY, isStrongPassword);
  }

  static removeIsStrongPassword() {
    localStorage.removeItem(CacheTool.IS_STRONG_PASSWORD_KEY);
  }

  static getIsStrongPassword() {
    return localStorage.getItem(CacheTool.IS_STRONG_PASSWORD_KEY);
  }

  //account
  static saveAccountInfo(accountInfo) {
    localStorage.setItem(CacheTool.ACCOUNT_INFO_KEY, JSON.stringify(accountInfo));
  }

  static removeAccountInfo() {
    localStorage.removeItem(CacheTool.ACCOUNT_INFO_KEY);
  }

  static getAccountInfo() {
    return JSON.parse(localStorage.getItem(CacheTool.ACCOUNT_INFO_KEY));
  }

  //mainSelectedModule
  static saveSelectedModule(module) {
    localStorage.setItem(CacheTool.MAIN_SELECTED_MODULE_KEY, JSON.stringify(module));
  }

  static removeSelectedModule() {
    localStorage.removeItem(CacheTool.MAIN_SELECTED_MODULE_KEY);
  }

  static getSelectedModule() {
    return JSON.parse(localStorage.getItem(CacheTool.MAIN_SELECTED_MODULE_KEY));
  }
  //保存机构数据
  static saveOrganizationModule(module) {
    localStorage.setItem(CacheTool.ORGANIZATION_KEY, JSON.stringify(module));
  }

  static removeOrganizationModule() {
    localStorage.removeItem(CacheTool.ORGANIZATION_KEY);
  }

  static getOrganizationModule() {
    if (!isEmpty(localStorage.getItem(CacheTool.ORGANIZATION_KEY))) {
      return JSON.parse(localStorage.getItem(CacheTool.ORGANIZATION_KEY));
    }
    return null;
  }

  static logout() {
    //删除普通全局变量
    CacheTool.removeLoginInfo()
    CacheTool.removeIsStrongPassword()
    CacheTool.removeAccountInfo()
    CacheTool.removeSelectedModule()
    CacheTool.removeOrganizationModule()
  }
}

export { CacheTool };
