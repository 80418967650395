var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fold"}},[(_vm.isShowList)?_c('div',{ref:"pickList",staticClass:"pick-list",style:({
      '--itemHeight': `${_vm.itemHeight}px`,
      '--listHeight': _vm.listHeight,
      '--paddingTop': `${_vm.paddingHeight}px`,
      '--enterAnimationTime': _vm.enterAnimationTime,
      '--leaveAnimationTime': _vm.leaveAnimationTime,
    })},_vm._l((_vm.itemArray),function(item,index){return _c('button',{key:index,staticClass:"pick-list-item",class:{ 'pick-list-item-selected': item == _vm.initialItem },on:{"click":function($event){return _vm.onSelected(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }