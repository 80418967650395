<template>
    <div class="tag-button">
        <patient-tag-popover v-if="tagCount" :patientDetail="patientDetail"></patient-tag-popover>
        <button v-else class="tag-button-add" @click.stop="onAdd">+</button>
        <panel-alert-add-tag v-if="isShowAddTagAlert" :patientDetail="patientDetail"
            @onClose="onTagAlertClose"></panel-alert-add-tag>
    </div>
</template>

<script>
import PatientTagPopover from "@c/main/patient/list-cell/tag-button/patient-tag-popover.vue";

export default {
    props: {
        patientDetail: Object
    },

    components: {
        PatientTagPopover,
        PanelAlertAddTag: () => import('@c/common/alert/panel-alert/panel-alert-add-tag/panel-alert-add-tag.vue')
    },

    data() {
        return {
            isShowAddTagAlert: false,
            checkIcon: require("@imgs/patient-list-patient-tag-check.png"),
        }
    },

    computed:{
        tagCount(){
            return this.patientDetail.userTagCount
        }
    },

    methods: {
        onAdd() {
            this.isShowAddTagAlert = true
        },

        onTagAlertClose() {
            this.isShowAddTagAlert = false
        }
    }
}
</script>

<style lang="scss" scoped>
.tag-button {
    &-add {
        background-color: #EFEFEF;
        color: #ABABAB;
        font-size: 18px;
        border: 1px solid #C9C9C9;
        border-radius: 11px;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
}
</style>