<template>
  <div class="list-picker">
    <el-select class="list-picker-content" v-model="value" :disabled="disabled" :popper-append-to-body="false"
      @change="onSelected">
      <el-option v-for="item in dataArray" :key="item.value" :label="item.title" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
export default {

  model: {
    prop: "initValue",
    event: "onChanged"
  },

  components: {
    elSelect: Select,
    elOption: Option,
  },

  props: {
    dataArray: Array,
    initValue: Number,
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      value: null
    }
  },

  mounted() {
    if (!isNaN(this.initValue)) {
      this.value = this.initValue
    }
  },

  watch: {
    initValue(value) {
      if (!isNaN(value)) {
        this.value = value
      }

    },

    value(value) {
      this.$emit("onChanged", value)
    }
  },

  methods: {
    onSelected() {
      const selectedIndex = this.dataArray.map((item) => {
        return item.value
      }).indexOf(this.value)
      this.$emit("onSelected", selectedIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-picker {
  width: 200px;
  display: flex;
  align-items: center;

  &-content {
    flex: 1;

    /deep/ .el-input__inner {
      color: black;
      font-size: 14px;
      border: 1px solid rgba($color: #000000, $alpha: 0.15);
      height: 32px;
      box-sizing: border-box;
      padding: 0 12px;
    }

    /deep/.el-input__suffix {
      margin-right: 10px;
    }

    //自定义右边图标
    /deep/.el-icon-arrow-up::before {
      content: "";
      background: url(../../../../../../assets/imgs/panel-alert-triangle-up.png) no-repeat center center;
      background-size: 12px 6px; //图片大小
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    /deep/.el-select-dropdown__item {
      color: rgba($color: #000000, $alpha: 0.65);
      padding-left: 12px;
    }

    /deep/.el-select-dropdown__item.selected {
      background-color: #E6F7FF;
    }
  }

}
</style>