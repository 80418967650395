<template>
  <p class="must-label" :style="{ minWidth: width, color: colorValue }">
    <span v-if="isMust" class="must-label-red">*</span>{{ text }}：
  </p>
</template>

<script>
export default {
  props: {
    text: String,
    isMust: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "78px",
    },
    color: {
      type: String,
      default: "black",
    },
  },

  computed: {
    colorValue() {
      switch (this.color) {
        case "red":
          return "#F45C50";
        case "yellow":
          return "#F39920";
        default:
          return "#262626";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.must-label {
  color: black;
  font-size: 14px;
  text-align: end;

  &-red {
    color: #ff0000;
  }
}
</style>