/**
 * 空值判断
 * @param value
 * @returns
 */
export const isEmpty = (value) =>
  value === '' || value === null || value === undefined || value === 'null' || value === 'undefined'

/**
 * 控住判断返回默认值
 */
export const ifEmpty = (value, defaultValue) => {
  return isEmpty(value) ? defaultValue : value
}

/**
 * 过滤Object空值
 */
export const filterEmpty = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => !!value));
}