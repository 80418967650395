<template>
  <div
    v-if="model"
    class="list-title-bar-item"
    @mouseout="mouseout"
    @mouseover="mouseover"
  >
    <p
      v-if="hasTitle"
      class="list-title-bar-item-title"
      :class="{ 'list-title-bar-item-title-hover': isHover }"
    >
      {{ model.title }}
    </p>
    <div
      class="list-title-bar-item-sub-titles"
      :class="
        hasTitle
          ? 'list-title-bar-item-sub-titles-gray'
          : 'list-title-bar-item-sub-titles-black'
      "
    >
      <p
        class="list-title-bar-item-sub-titles-item"
        :class="{ 'list-title-bar-item-sub-titles-item-hover': isHover }"
        v-for="(subTitle, index) of model.itemArray"
        :key="index"
      >
        {{ subTitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
  },

  data() {
    return {
      isHover: false,
    };
  },

  computed: {
    hasTitle() {
      return this.model.title != "";
    },
  },

  methods: {
    mouseover() {
      this.isHover = true;
    },

    mouseout() {
      this.isHover = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-title-bar-item {
  color: black;
  border-left: 1px solid #b3b3b3;
  height: 68px;
  box-sizing: border-box;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  &:first-child {
    border: none;
  }

  &-title {
    &-hover {
      color: #2e6be6;
    }
  }

  &-sub-titles {
    width: 100%;
    display: flex;
    justify-content: space-around;

    &-gray {
      color: #6b6b6b;
      font-size: 14px;
      margin-top: 16px;
    }

    &-black {
      color: #000000;
      font-size: 16px;
    }

    &-item {
      min-width: 90px;
      text-align: center;
      line-height: 16px;

      &-hover {
        color: #2e6be6;
      }
    }
  }
}
</style>