<template>
  <div class="pop-view" @click.stop="onMaskClick">
    <div class="pop-view-card" @click.stop>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onCardClick: function (event) {
      event.stopPropagation(); //停止穿透
    },

    onMaskClick: function () {
      this.$emit("onMaskClick");
    },
  },

  // 防止滚动穿透
  mounted() {
    // 或者您可以针对特定的容器进行限制，例如：document.querySelector('.').style.overflow = 'hidden';
    // document.body.style.overflow = 'hidden';
  },

  beforeUnmount() {
    console.log("🌽")
    // document.body.style.overflow = '';
  },
};
</script>

<style lang="scss" scoped>
.pop-view {
  @include z-index-max;
  background-color: rgba($color: #666666, $alpha: 0.2);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; // 防止滚动穿透

  &-card {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 0px 15px 0px rgba(black, 0.1);
  }
}
</style>