<template>
  <div class="module-picker">
    <drop-button class="module-picker-item" v-for="(item, index) of dataArray" :key="index" :model="item" :index="index"
      :currentModuleTitle="currentModuleTitle" @onSelected="onSelected" @onSelectedTitle="onSelectedTitle"></drop-button>
  </div>
</template>

<script>
import DropButton from "@c/main/main//module-picker/drop-button.vue";
import { CacheTool } from "@js/cache-tool.js";
export default {
  components: {
    DropButton,
  },

  data() {
    return {
      dataArray: [
        {
          title: "工作日历",
          itemArray: [],
        },
        {
          title: "患者列表",
          itemArray: [
            "患者列表",
            "睡眠监测",
            "PAP治疗",
            "患者随访",
          ],
        },
        {
          title: "远程滴定",
          itemArray: [],
        },
        {
          title: "系统设置",
          itemArray: [],
        },
        {
          title: "依从性数据",
          itemArray: [],
        },
      ],
      currentModuleTitle: CacheTool.getSelectedModule()?.title ?? "患者列表",
    };
  },

  methods: {
    onSelected(info) {
      const currentItemIndex = info.index;
      const currentItemSelectedIndex = info.selectedIndex;
      this.currentModuleTitle =
        this.dataArray[currentItemIndex].itemArray[currentItemSelectedIndex];
      this.$emit("onSelected", this.currentModuleTitle);
    },

    onSelectedTitle(info) {
      const currentItemIndex = info.index;
      this.currentModuleTitle = this.dataArray[currentItemIndex].title;
      this.$emit("onSelected", this.currentModuleTitle);
    }
  },
};
</script>

<style lang="scss" scoped>

.module-picker {
  display: flex;

  &-item {
    width: 101px;
    height: 60px;
  }
}
</style>