<template>
  <div class="top-bar" :class="type == 'blue' ? 'top-bar-blue' : 'top-bar-white'"
    :style="{ height: type == 'blue' ? `65px` : `83px` }">
    <div class="top-bar-left">
      <button v-if="type == 'white'" class="top-bar-left-back" @click="onBack">
        <img class="top-bar-left-back-icon" :src="backIcon">
        <p class="top-bar-left-back-title">返回</p>
      </button>
      <slot name="top-bar-left"></slot>
    </div>
    <div class="top-bar-right">
      <slot name="module-picker"></slot>
      <remind class="top-bar-right-remind" :remindNumber="remindNumberStr"
        :icon="type == 'blue' ? blueBarBellIcon : whiteBarBellIcon" @onClickRemind="$emit('onClickRemind')"></remind>
      <avatar-button class="top-bar-right-avatar-button"></avatar-button>
      <p class="top-bar-right-user-name"
        :class="type == 'blue' ? 'top-bar-right-user-name-blue' : 'top-bar-right-user-name-white'">{{ userName }}</p>
    </div>
  </div>
</template>

<script>
import Remind from "@c/common/top-bar/remind.vue";
import AvatarButton from "@c/common/top-bar/avatar-button.vue";
import { CacheTool } from "@js/cache-tool.js";
export default {
  components: {
    Remind,
    AvatarButton,
  },

  props: {
    type: String,
    remindNumber: Number
  },

  data() {
    return {
      hospitalName: placeholderText(CacheTool.getAccountInfo().hospitalName),
      userName: placeholderText(CacheTool.getAccountInfo().name),
      backIcon: require("@imgs/user-main-back.png"),
      blueBarBellIcon: require("@imgs/main-bell.png"),
      whiteBarBellIcon: require("@imgs/user-main-bell.png"),
      isShowModifyPasswordAlert: false,
    };
  },

  computed: {
    remindNumberStr() {
      return this.remindNumber ? this.remindNumber > 99 ? '99+': `${this.remindNumber}` : ''
    }
  },

  methods: {
    onBack() {
      this.$emit("onBack")
    }
  }

};
</script>

<style lang="scss" scoped>
.top-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-blue {
    color: white;
    background-color: #2e6be6;
  }

  &-white {
    color: #2e6be6;
    background-color: white;
  }


  &-left {
    flex: 1;
    display: flex;

    &-back {
      display: flex;
      align-items: center;
      margin-left: 44px;

      &-icon {
        width: 11px;
        height: 17px;
      }

      &-title {
        color: #5889EB;
        font-size: 18px;
        margin-left: 4px;
      }
    }

    &-title {
      font-size: 22px;
      font-weight: bold;
      margin-left: 35px;
    }
  }

  &-right {
    margin-right: 27px;
    display: flex;
    align-items: center;

    &-avatar-button {
      margin-left: 28px;
      margin-right: 18px;
    }

    &-user-name {
      font-size: 16px;

      &-blue {
        color: white;

      }

      &-white {
        color: #2E6BE6;
      }
    }

    &-module-picker {
      margin-right: 47px;
    }
  }
}
</style>